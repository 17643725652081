import React from "react"
import { Pagination } from "reactstrap"
import { Link } from "gatsby"
import { BsCaretRight, BsCaretLeft } from "react-icons/bs"

const Links = ({ currentPage, numberOfpage }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfpage
  const previousPage =
    currentPage - 1 === 1
      ? "/gallery"
      : "/gallery/" + (currentPage - 1).toString()

  const nextPage = "/gallery/" + (currentPage + 1).toString()

  return (
    <Pagination aria-label="Page navigation example">
      {!isFirst && (
        <li className="text-center" key="prev">
          <Link activeClassName="p-active" to={previousPage} rel="prev">
            <BsCaretLeft size={20} />
          </Link>
        </li>
      )}

      {Array.from({ length: numberOfpage }, (_, i) =>
        currentPage === i + 1 ? (
          <li className="text-center" key={`page-number${i + 1}`}>
            <Link
              activeClassName="p-active"
              to={`/${i === 0 ? "gallery" : "gallery/" + (i + 1)}`}
            >
              {i + 1}
            </Link>
          </li>
        ) : (
          <li className="text-center" key={`page-number${i + 1}`}>
            <Link
              activeClassName="p-active"
              to={`/${i === 0 ? "gallery" : "gallery/" + (i + 1)}`}
            >
              {i + 1}
            </Link>
          </li>
        )
      )}
      {!isLast && (
        <li className="text-center" key="next">
          <Link activeClassName="p-active" to={nextPage} rel="next">
            <BsCaretRight size={20} />
          </Link>
        </li>
      )}
    </Pagination>
  )
}

export default Links
