import React from "react"
import { Card, CardColumns } from "reactstrap"
import Img from "gatsby-image"

const ThumbGrid = ({ images, handleOpen }) => {
  return (
    <CardColumns>
      {images.map((image, i) => (
        <div className="gallery-col" md="4" key={i}>
          <Card onClick={handleOpen(i)} className="previewButton show-image">
            <Img
              fluid={image.node.childImageSharp.fluid}
              className="thumbnail"
            />
          </Card>
        </div>
      ))}
    </CardColumns>
  )
}

export default ThumbGrid
