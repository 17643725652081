import React, { useState } from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import { Container } from "reactstrap"
import { graphql } from "gatsby"
import LightBox from "../components/gallery/lightbox"
import ThumbGrid from "../components/gallery/thumbnails"
import Links from "../components/gallery/links"

const GalleryList = ({ data, pageContext }) => {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpen = i => e => {
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => e => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => e => {
    setSelectedImage((i + 1) % length)
  }

  //gallery data
  const images = data.allFile.edges
  const { currentPage } = pageContext

  //photos per pages
  const photoserPage = 9
  //pagination
  let numberOfPages = Math.ceil(data.allFile.totalCount / photoserPage)

  return (
    <Layout>
      <SEO title="Gallery" keywords="frank weiler deli, frank, weler, deli" />
      <PageTitle title="Gallery" />
      <section id="gallery">
        <Container>
          <ThumbGrid images={images} handleOpen={handleOpen} />
          {showLightbox && selectedImage !== null && (
            <LightBox
              images={images}
              handleClose={handleClose}
              handleNextRequest={handleNextRequest}
              handlePrevRequest={handlePrevRequest}
              selectedImage={selectedImage}
            />
          )}
        </Container>
        <div className="d-flex justify-content-center">
          <Links currentPage={currentPage} numberOfpage={numberOfPages} />
        </div>
      </section>
    </Layout>
  )
}
export default GalleryList

export const query = graphql`
  query galleryTemplate($skip: Int!, $limit: Int!) {
    allFile(
      filter: { sourceInstanceName: { eq: "gallery" } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`
